.geo {
  position: absolute;
  width: 98.5%;

  &-settings {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(#000, 0.2);
  }
  .settings-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background: transparent;
    padding: 5px;
  }

  .columns-wrapper {
    padding: 10px 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: calc(100vh - 183px);

    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0d6efd;
      border-radius: 10px;
    }

    .column {
      display: inline-block;
      height: 100%;
      overflow-y: scroll;
      scroll-margin: 0 -5px;
      overflow-x: hidden;
      white-space: nowrap;
      width: 600px;
      padding: 0px 10px 15px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b30000;
      }

      &:first-child {
        padding-left: 2px;
      }

      .accordion-item {
        position: relative;

        .accordion-header {
          &.active-category {
            .accordion-button {
              background: rgba(green, 0.2);
            }
          }
          .accordion {
            &-button {
              padding-left: 10px;
              padding-right: 10px;
            }
            &_page-type {
              margin-right: 5px;
            }
          }

          button {
            position: relative;
          }

          .accordion_page-url {
            position: absolute;
            top: 17px;
            left: 40px;
            cursor: zoom-in;
            min-width: 50px;
            text-decoration: underline;
            color: #0d6efd;
          }
        }

        .accordion-body {
          white-space: normal;
          padding: 10px;
        }
      }

      .cluster-table {
        td,
        th {
          text-align: center;

          &:first-child {
            width: 10px;
          }
          &:nth-child(2) {
            text-align-last: left;
          }
        }
      }

      .sc-bcXHqe.lkjDVC {
        max-width: 100%;
        height: 60px;
      }
    }
  }
}
