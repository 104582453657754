.content-page {
  &_content {
    // min-height: 500px;
  }
}

.right-side-panel {
  display: flex;
  height: calc(100vh - 66px);
  flex-direction: column;

  .chat_chat-gpt {
    overflow-y: auto;
  }
}
