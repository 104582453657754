.content {
  min-height: calc(100vh - 56px);
  width: 100%;
}

.hide {
  display: none;
}

.inline-block {
  display: inline-block;
}
.settings-btn {
  border: none;
  background: transparent;
  padding: 5px;
}

.sort-btn {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.two-side_group-buttons {
  display: flex;
  justify-content: space-between;
}
