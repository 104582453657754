.project-header {
    position: relative;
    min-height: 50px;
    border: solid 1px rgba($color: #000000, $alpha: 0.1);
    border-radius: 10px;


    &__title {
        text-align: center;
    }
    .settings-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        background: transparent;
        padding: 5px
    }

    .project-settings {
        .row-heght-fix {
            min-height: 38px;
        }
        .form-switch {
            margin-top: 6px;
        }
    }

}