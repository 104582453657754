.analytics-layout {
    margin: 0;

    &-left-menu {

        padding-top: 10px;
        padding-right: 10px;
        padding-left: 0;
    }
    &-workspace {
        position: relative;
        border-left: solid 0.5px rgba($color: #000000, $alpha: 0.1);
        min-height: calc(100vh - 56px);
        padding-top: 10px;
        padding-right: 0;

        h1 {
            text-align: center;
        }

        .hide-btn {
            position: absolute;
            left: 0;
            top: 50%;
            bottom: 50%;
            padding: 0;
            border: none;
            background: transparent;

            &.active {
                left: -19px;
                svg {
                    transform: rotate(180deg);
                }
            }

        }
    }


    .accordion-item {
        position: relative;

        .accordion-header {
            .accordion-button {
                padding-left: 30px;
                padding-right: 5px;
            }
        }
    }
    .dropdown {
        position: absolute;
        top: 9px;
        left: 0;
        z-index: 5;
        margin: 0;

        .dropdown-toggle{
            padding: 0 4px;
            vertical-align: middle;

            &::after {
                display: none;
            }
        }
    }
}